import React, { useEffect, useRef } from "react";
import "../SecondSec/SecondSec.css";

import Vector from "../../assets/Vector.png";
// import CloudSmall from "../../assets/Cloud-small.png";
// import CloudBig from "../../assets/Cloud-big.png";

import T1 from "../../assets/T1.png";
import T2 from "../../assets/T2.png";
import T3 from "../../assets/T3.png";
import T4 from "../../assets/T4.png";
import T5 from "../../assets/T5.png";
import T6 from "../../assets/T6.png";
import T7 from "../../assets/T7.png";
import T8 from "../../assets/T8.png";

import text3 from "../../assets/text3.png";
import text4 from "../../assets/text4.png";

import top from "../../assets/top.svg";

const Accordion = ({ children }) => {
  let rfVal = useRef(null);

  useEffect(() => {
    handleClick();
  }, []);

  const handleClick = () => {
    const acc = rfVal.children;
    for (let i = 0; i < acc.length; i++) {
      let a = acc[i];
      a.children[0].onclick = () => a.classList.toggle("active");
    }
  };

  return <div ref={(a) => (rfVal = a)}>{children}</div>;
};

const SecondSec = () => {
  return (
    <>
      <div className="first-sec">
        <div className="text-center">
          <img className="text3" src={text3} alt="png" />
          <Accordion>
            <div className="accor">
              <div className="head noselect">
              <div className="FAQ-Text">
                "What's the mint price? How much to go on a date with a Rabbit?”
                </div>
                <img className="Accordion-icon" src={top} alt="png" />
              </div>
              <div className="body">"0.0369 ETH"</div>
            </div>
            <div className="accor">
              <div className="head noselect">
              <div className="FAQ-Text">
                "How many good-looking Rabbits are there?”
                </div>
                <img className="Accordion-icon" src={top} alt="png" />
              </div>
              <div className="body">"Total of 10k Rabbits in the Moon and they are all SEXY."</div>
            </div>
            <div className="accor">
              <div className="head noselect">
              <div className="FAQ-Text">
                "When can I book a date??”
                </div>
                <img className="Accordion-icon" src={top} alt="png" />
              </div>
              <div className="body">"Six Nine! In case you are too young to realize, it is 9th June."</div>
            </div>
            <div className="accor">
              <div className="head noselect">
              <div className="FAQ-Text">
                "How many Rabbits can I take on a date at a time?”
                </div>
                <img className="Accordion-icon" src={top} alt="png" />
              </div>
              <div className="body">
                "Max 1 per your fancy wallet."
              </div>
            </div>
            <div className="accor">
              <div className="head noselect">
              <div className="FAQ-Text">
                "Any Roadmap?”
                </div>
                <img className="Accordion-icon" src={top} alt="png" />
              </div>
              <div className="body">
                "No Roadmap but we will take you to the moon with no doubt."
              </div>
            </div>
            <div className="accor">
              <div className="head noselect">
              <div className="FAQ-Text">
                "Discord?”
                </div>
                <img className="Accordion-icon" src={top} alt="png" />
              </div>
              <div className="body">
                "No Discord unless you want to share stories of your private date."
              </div>
            </div>
            <div className="accor">
              <div className="head noselect">
                <div className="FAQ-Text">"Do I own the IP rights of my Rabbits?”</div>
                <div>
                  <img className="Accordion-icon" src={top} alt="png" />
                </div>
              </div>
              <div className="body">"Yes. Do however and whatever you want with our Rabbits."</div>
            </div>
          </Accordion>
        </div>
      </div>
      <div className="rabbit_img_box">
        <div className="rabbit_img3">
          {/* <img className="Cb" src={CloudBig} alt="png" /> */}
        </div>
        <div className="rabbit_img4">
          {/* <img className="Cs" src={CloudSmall} alt="png" /> */}
        </div>
      </div>
      <div className="first-sec">
        <div className="text-center">
          <img className="text4" src={text4} alt="png" />
        </div>
      </div>
      <div className="team-img">
      <div className="imgSlide">
        <div>
          <img className="Second-slideImg" src={T1} alt="png" />
          <p>Marston</p>
          <a>CD</a>
        </div>
        <div>
          <img className="Second-slideImg" src={T2} alt="png" />
          <p>Durex</p>
          <a>Developer</a>
        </div>
        <div>
          <img className="Second-slideImg" src={T3} alt="png" />
          <p>Banger</p>
          <a>Operator</a>
        </div>
        <div>
          <img className="Second-slideImg" src={T4} alt="png" />
          <p>LibrationX</p>
          <a>Artist</a>
        </div>
      </div>
      <div className="imgSlide2">
        <div>
          <img className="Second-slideImg" src={T5} alt="png" />
          <p>Maso</p>
          <a>Designer</a>
        </div>

        <div>
          <img className="Second-slideImg" src={T6} alt="png" />
          <p>Sadist</p>
          <a>..?</a>
        </div>

        <div>
          <img className="Second-slideImg" src={T7} alt="png" />
          <p>Bunnygirl</p>
          <a>Marketer</a>
        </div>

        <div>
          <img className="Second-slideImg" src={T8} alt="png" />
          <p>ParkingMaster</p>
          <a>Marketer</a>
        </div>
        {/* <img className="slideImg" src={R6} alt='png' /> */}
      </div>
      </div>
      <div className="imagesB">
        <img src={Vector} alt="png" />
        <img src={Vector} alt="png" />
      </div>
    </>
  );
};

export default SecondSec;

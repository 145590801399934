import "../../App.css";
import "./Banner.css";

// import I1 from "../../assets/I1.png";
// import I2 from "../../assets/I2.png";

const Banner = () => {
  return (
    <div className="Banner">
      {/* <div className="Banner-sec">
        <div className="sec1">
        <div className="six-things"/>
        <div className="six-things"/>
        <div className="six-things"/>
        </div>
        <div className="sec2">
        <div className="six-things"/>
        <div className="six-things"/>
        <div className="six-things"/>
        </div>
      </div> */}
        {/* <img className="I1" src={I1} alt="png" />
        <img className="I2" src={I2} alt="png" /> */}
    </div>
  );
};

export default Banner;

import './App.css';
import './font/fonts.css';
import Header from './Components/Header/Header';
import Banner from './Components/Banner/Banner';
import star from './assets/star.png';
import FirstSec from './Components/FirstSec/FirstSec';
import SecondSec from './Components/SecondSec/SecondSec';
import Footer from './Components/Footer/Footer';
// import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <header className="App-header">
      <Banner />
      <img className='star' src={star} alt="png" />
      <FirstSec />
      </header>
      {/* <Footer /> */}
      <header className="App-header-2">
      <SecondSec />
      </header>
      <Footer/>
    </div>
  );
}

export default App;

import "./FirstSec.css";
import text1 from "../../assets/text1.png";
import text2 from "../../assets/text2.png";

// import CloudSmall from "../../assets/Cloud-small.svg";
// import CloudBig from "../../assets/Cloud-big.svg";

const RrbbitsIcon = (props) => {
  return <div className="rabbit_icon_box">{props.children}</div>;
};

const FirstSec = () => {
  return (
    <>
      <div className="first-sec">
        <div className="text-center-top">
        <a className="conntectBtn2" href="https://mint.moonrabbits.wtf/"></a>
        <a className="conntectBtn3" href="https://breedingpass.moonrabbits.wtf/"></a>
          <img className="text1" src={text1} alt="svg" />
          <p>No-Roadmap, No-Discord, No-Protection</p>
          <br />
          <div className="first-sec-text">
            <a className="text1-1">Why Moon Rabbits?!</a>
            <h1>We are Sexy.</h1>
            <p>
              We know proper Etiquette.
              <br />
              We will give you the love you never Xperienced.
              <br />
              The phrase “giving up” does not exist in our world.
              <br />
              We won’t stop until we take and own what we want for that is what
              we are good at.
              <br />
              We don’t look back as we simply hop over any difficulties or
              troubles.
            </p>
            <div className="first-sec-text box">
              <p>
                In just 3 seconds, we get your attention.
                <br />In just 6 minutes, we have you fascinated.
                <br />In just 9 hours, you'll fall in love with us.
              </p>
            </div>
            <p>
              Then, we are going to moon you to show you what we got!
              <br />
              After that, we will continue to expand under the same moon.
            </p>
            {/* <div className="first-sec-text">
            MoonRabbits are a collection of 10,000 PFPs, each Moonrabits has
            each different outfits and traits, Did you know an ancient Legend
            that Moon is originally hometown of the rabbits? These Moonrabbits
            will guide you to beyond the moon, the very secret place for
            Moonrabbits, we don't know anything what's going on, but one thing
            that we know about the beyond the moon, is the place with full of
            fantasy will comes true.
          </div> */}
          </div>
        </div>
        <div className="first-sec first">
          <div className="M-NO">
            <div className="dis" />
            <div className="road" />
          </div>
          <div className="RB">
            <h1>#RABBITSFOLLOWRABBITS</h1>
          </div>
        </div>
        <div className="first-sec second">
          <div className="NO">
            <h3 className="NO-Before">none</h3>

            <h3 className="NO-After">
              We don’t have any discord.
              <br />
              We don’t have any roadmap.
              <br />
              We don’t look back as we simply
              <br />
              hop over any difficulties or
              <br />
              troubles.
              <br />
              <br />
              We’re CC0 and fair launch.
              <br />
              0.0369 + gas mint per wallet.
            </h3>
          </div>
          <div className="MO">
            <div className="MO-box">
              <p className="MO-text">
                Follow the Moon Rabbits as they will be the BEST you ever had!
                Let them take you to the moon and fill deep inside you with the
                moon.
                <br />
                <br />
                Don’t resist just feel em🌙
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="rabbit_img_box_first">
        <div className="rabbit_img1">
          {/* <img className="Cb" src={CloudBig} alt="png" /> */}
        </div>
        <div className="rabbit_img2">
          {/* <img className="Cs" src={CloudSmall} alt="png" /> */}
        </div>
      </div>
      <div className="first-sec">
        <div className="text-center">
          <img className="text2" src={text2} alt="png" />
          <h3>
            "Mint date is six nine. In case you wonder, it's 9th June. You
            obviously know why for this concept will be our identity. This is a
            very symbolic day celebrating our new birth and the beginning of
            becoming the one. Youngins won't know our deep philosophy as many
            saints have already proved the symbolic meaning behind the number
            369."
          </h3>
        </div>
      </div>

      <div className="Rabbit_slide">
        <div className="content3">
          <RrbbitsIcon>
            <div className="rabbit_icon rabbit_icon1"></div>
            <div className="rabbit_icon rabbit_icon2"></div>
            <div className="rabbit_icon rabbit_icon3"></div>
            <div className="rabbit_icon rabbit_icon4"></div>
            <div className="rabbit_icon rabbit_icon5"></div>
            <div className="rabbit_icon rabbit_icon6"></div>
            <div className="rabbit_icon rabbit_icon7"></div>
            <div className="rabbit_icon rabbit_icon1"></div>
            <div className="rabbit_icon rabbit_icon2"></div>
          </RrbbitsIcon>
          <RrbbitsIcon>
            <div className="rabbit_icon rabbit_icon3"></div>
            <div className="rabbit_icon rabbit_icon4"></div>
            <div className="rabbit_icon rabbit_icon5"></div>
            <div className="rabbit_icon rabbit_icon6"></div>
            <div className="rabbit_icon rabbit_icon7"></div>
            <div className="rabbit_icon rabbit_icon1"></div>
            <div className="rabbit_icon rabbit_icon2"></div>
            <div className="rabbit_icon rabbit_icon3"></div>
            <div className="rabbit_icon rabbit_icon4"></div>
          </RrbbitsIcon>
        </div>
      </div>
      {/* <div className="images">
        <img src={Mountain} alt="png" />
        <img src={Mountain} alt="png" />
      </div> */}
    </>
  );
};

export default FirstSec;

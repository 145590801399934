/* eslint-disable jsx-a11y/anchor-has-content */
import "../../App.css";
import "./Header.css";
import LOGO from "../../assets/logo.png";

const Header = () => {
  return (
    <div className="App">
      <div className="Header-sec">
        <div className="Header-logo">
          <div className="main-logo-sec">
            <div className="main-logo-sec-1">
              <img className="logo" src={LOGO} alt="png" />
            </div>
          </div>
        </div>
        <div>
        <a className="conntectBtn4" href="https://breedingpass.moonrabbits.wtf/" />
        <a className="conntectBtn" href="https://mint.moonrabbits.wtf/" />
        </div>
      </div>
    </div>
  );
};

export default Header;

import FooterLogo from '../../assets/FooterLogo.png';
import './Footer.css';

const Footer = () => {
    return (
        <div className='Footer'>
            <img className="Footer-logo" src={FooterLogo} alt='png' />
            <p>ⓒ 2022 Moon Rabbits</p>
        </div>
    )
}

export default Footer;